
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'MotivationView',
  components: {
  },
  
  data: function(){
    return{
      InitialValue:"",
      isYellow1:false,
      isYellow2:false,
      isYellow3:false,
      isYellow4:false,
      isGray1:true,
      isGray2:true,
      isGray3:true,
      isGray4:true,

    }
  },

  methods: {
    isactive1(){
      this.isYellow1 = !this.isYellow1;
      this.isGray1 = !this.isGray1;

      this.isYellow2 = false;
      this.isGray2 = true;

      this.isYellow3 = false;
      this.isGray3 = true;

      this.isYellow4 = false;
      this.isGray4 = true;
    },

    isactive2(){
      this.isYellow2 = !this.isYellow2;
      this.isGray2 = !this.isGray2;

      this.isYellow1 = false;
      this.isGray1 = true;

      this.isYellow3 = false;
      this.isGray3 = true;

      this.isYellow4 = false;
      this.isGray4 = true;
    },

    isactive3(){
      this.isYellow3 = !this.isYellow3;
      this.isGray3 = !this.isGray3;

      this.isYellow1 = false;
      this.isGray1 = true;

      this.isYellow2 = false;
      this.isGray2 = true;

      this.isYellow4 = false;
      this.isGray4 = true;
    },

    isactive4(){
      this.isYellow4 = !this.isYellow4;
      this.isGray4 = !this.isGray4;

      this.isYellow1 = false;
      this.isGray1 = true;

      this.isYellow2 = false;
      this.isGray2 = true;

      this.isYellow3 = false;
      this.isGray3 = true;
    }
  },

  mounted(){
    const setFillHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // 画面のサイズ変動があった時に高さを再計算する
    window.addEventListener('resize', setFillHeight);

    // 初期化
    setFillHeight();
  }
});
