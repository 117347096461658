
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'SexView',
  components: {
  },
  
  data: function(){
    return{
      InitialValue:"",
      MenColor:0,
      WomenColor:0,
    }
  },

  methods: {
    MenColorChange(){
      if(this.MenColor == 0){
        this.MenColor ++
        this.WomenColor = 0
      }
    },

    WoMenColorChange(){
      if(this.WomenColor == 0){
        this.WomenColor ++
        this.MenColor = 0
      }
    }
  },

  mounted(){
    const setFillHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // 画面のサイズ変動があった時に高さを再計算する
    window.addEventListener('resize', setFillHeight);

    // 初期化
    setFillHeight();
  }
});
